// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import Element from 'element-ui'
import locale from 'element-ui/lib/locale/lang/en'
import store from './store'
import MainSportFilter from './components/filters/MainSportFilter'
import FooterFilters from './components/filters/FooterFilters'
import SideFilter from './components/filters/SideFilter'
import DetailFilter from './components/filters/DetailFilter'
import PopularSportsFilter from './components/filters/PopularSportsFilter'
import ChannelsResult from './components/results/ChannelsResult'
import LeaguesResult from './components/results/LeaguesResult'
import GamesResult from './components/results/GamesResult'
import TeamsResult from './components/results/TeamsResult'
import SliderGamesResult from './components/results/SliderGamesResult'
import DetailGamesResult from './components/results/DetailGamesResult'
import FooterResult from './components/results/FooterResult'
import PopularSportsResult from './components/results/PopularSportsResult'
import Navbar from './components/Navbar'
import CreateLeague from './components/CreateLeague'
import VideoCarousel from './components/VideoCarousel'
import VideoModal from './components/VideoModal'
import Partners from './components/Partners'
import RecentGamesFilter from './components/filters/RecentGamesFilter'
import PopularGamesFilter from './components/filters/PopularGamesFilter'
import MostViewedGamesFilter from './components/filters/MostViewedGamesFilter'
import SubscriptionStep from './components/SubscriptionStep'
import Clubs from './components/Clubs'
import Teams from './components/Teams'
import Subscription from './components/Subscription'
import CountrySelector from './components/CountrySelector'
import VideoPlayer from './components/video_player/VideoPlayer'
import VideoPlayerLive from './components/video_player/VideoPlayerLive'
import VideoPlayerFullgame from './components/video_player/VideoPlayerFullgame'
import VideoPlayerMulticam from './components/video_player/VideoPlayerMulticam'
import Blog from './components/Blog'
import VueApollo from 'vue-apollo'
import {apolloClient} from './graphql'
import axios from 'axios'
import {parse_cookies} from "./utils";
import Slick from 'vue-slick';
import VueScrollTo from 'vue-scrollto';
import VueMq from 'vue-mq'
import numeral from 'numeral';
import SocialSharing from 'vue-social-sharing';
import './filters'
import './sass/vendor.scss'
import './sass/style.scss'

import 'vue-loading-overlay/dist/vue-loading.css';
import Registration from "./components/Registration";
import CustomSocialSharing from "./components/CustomSocialSharing";
import VueLoading from "vue-loading-overlay";
import IframeTopbar from "./components/filters/IframeTopbar";
import EventBus from './event_bus';
import debounce from 'lodash/debounce';
import Modal from "./components/modal/Modal.vue";

window.axios = axios;

Vue.use(Element, {locale});
Vue.use(SocialSharing);

Vue.mixin({
    methods: {
        gettext(text) {
            return window.gettext(text);
        }
    }
})

Vue.component('sport-filter', MainSportFilter);
Vue.component('sport-side-filter', SideFilter);
Vue.component('detail-filter', DetailFilter);
Vue.component('iframe-topbar', IframeTopbar);
Vue.component('channels-result', ChannelsResult);
Vue.component('leagues-result', LeaguesResult);
Vue.component('games-result', GamesResult);
Vue.component('teams-result', TeamsResult);
Vue.component('detail-games-result', DetailGamesResult);
Vue.component('recent-games-filter', RecentGamesFilter);
Vue.component('popular-games-filter', PopularGamesFilter);
Vue.component('slider-games-result', SliderGamesResult);
Vue.component('most-viewed-games-filter', MostViewedGamesFilter);
Vue.component('video-player', VideoPlayer);
Vue.component('video-player-live', VideoPlayerLive);
Vue.component('video-player-fullgame', VideoPlayerFullgame);
Vue.component('video-player-multicam', VideoPlayerMulticam);
Vue.component('popular-sports-filter', PopularSportsFilter);
Vue.component('popular-sports-result', PopularSportsResult);
Vue.component('clubs', Clubs);
Vue.component('teams', Teams);
Vue.component('subscription', Subscription);
Vue.component('country-selector', CountrySelector);
Vue.component('subscription-step', SubscriptionStep);
Vue.component('footer-result', FooterResult);
Vue.component('footer-filters', FooterFilters);
Vue.component('registration', Registration);
Vue.component('custom-social-sharing', CustomSocialSharing);
Vue.component("Modal", Modal);


Vue.use(VueScrollTo, {
    container: "body",
    duration: 500,
    easing: "ease",
    offset: 0,
    cancelable: true,
    onStart: false,
    onDone: false,
    onCancel: false,
    x: false,
    y: true
})
Vue.use(VueLoading, {
    color: '#fa1f63',
    loader: 'dots'
});

Vue.use(VueMq, {
    breakpoints: {
        sm: 768,
        md: 992,
        lg: 1200,
    }
})

const apolloProvider = new VueApollo({
    defaultClient: apolloClient,
});

var cookies = parse_cookies();

new Vue({
    delimiters: ['[[', ']]'],
    el: '#app',
    provide: apolloProvider.provide(),
    store,
    data() {
        return {
            showComments: ['1'],
            donationVisible: false,
            selectSport3: '',
            sportOptions: [],
            howItWorksSlickOptions: {
                arrows: false,
                responsive: [
                    {
                        breakpoint: 9999,
                        settings: "unslick",
                    },
                    {
                        breakpoint: 768,
                        settings: {
                            slidesToShow: 1,
                            arrows: true,
                            dots: true,
                            infinite: false,
                            prevArrow: false,
                            nextArrow: '<div class="step-icon-xs"><i class="fas fa-chevron-right step-icon"></i></div>',
                        }
                    }
                ]
            },
            blogOptions: {
                arrows: false,
                responsive: [
                    {
                        breakpoint: 9999,
                        settings: "unslick",
                    },
                    {
                        breakpoint: 768,
                        settings: {
                            slidesToShow: 1,
                            arrows: false,
                            dots: true,
                            infinite: true,
                            variableWidth: true,
                        }
                    }
                ]
            },
            videoTutorialsOptions: {
                arrows: false,
                responsive: [
                    {
                        breakpoint: 9999,
                        settings: "unslick",
                    },
                    {
                        breakpoint: 992,
                        settings: {
                            slidesToShow: 1,
                            arrows: false,
                            dots: true,
                        }
                    }
                ]
            },
            tileWithImagesOptions: {
                arrows: false,
                responsive: [
                    {
                        breakpoint: 9999,
                        settings: "unslick",
                    },
                    {
                        breakpoint: 768,
                        settings: {
                            slidesToShow: 2,
                            arrows: false,
                            dots: true,
                            centerMorde: true,
                        }
                    }
                ]
            },
            openedFilter: true,
            openedFilterChannel: true,
            channelDetailMobileFilter: true,
            currentHeight: null
        }
    },
    components: {
        Navbar,
        VideoCarousel,
        Slick,
        CreateLeague,
        VideoModal,
        Partners,
        Blog,
    },
    created() {
        EventBus.$on('sendHeightMessage', this.sendHeightMessage);
        this.$store.commit('global_data/setUrls', Urls);
        axios.defaults.headers.common = {
            'X-Requested-With': 'XMLHttpRequest',
            'X-CSRFToken': cookies['csrftoken']
        };

        window.fillCommentHighlightInfo = this.fillCommentHighlightInfo;
    },
    methods: {
        openFilter: function () {
            this.openedFilter = !this.openedFilter;
        },
        openFilterChannel: function () {
            this.openedFilterChannel = !this.openedFilterChannel;
        },
        get_number_formatted(number) {
            return numeral(number).format('0 a')
        },
        sendHeightMessage: debounce(function (e) {
            if(!window.parent) {
                return
            }
            const newHeight = document.documentElement.offsetHeight + 20;
            if(!this.currentHeight || this.currentHeight !== newHeight) {
                this.currentHeight = newHeight;
                window.parent.postMessage(this.currentHeight, '*');
            }
        }, 500),
        initFromParent() {
            window.removeEventListener('message', this.initFromParent);
            this.sendHeightMessage();
        },
        triggerVideo(id, tab) {
            this.$refs['player'].triggerVideoFromComment(id, tab);
        },
        fillCommentHighlightInfo(form) {
            if (form.querySelector("#id_reply_to").value !== '0') {
                return;
            }

            const highlight_info = form.querySelector('#id_highlight_info')
            if (highlight_info) {
                highlight_info.value = JSON.stringify(this.$refs['player'].getCommentBadgeData())
            }
        }
    },
    mounted() {
        if (this.$mq === 'sm') {
            this.channelDetailMobileFilter = false;
        }
        this.$root.$on('show-donations', () => {
            this.donationVisible = true;
        });
        window.addEventListener('message', this.initFromParent, false);

        document.querySelectorAll('#highlight_badge_link').forEach(
            (element) => {
                element.style.cursor = 'pointer';
                element.addEventListener('click', () => {
                    this.triggerVideo(element.getAttribute('data-video'), element.getAttribute('data-tab'));
                })
            }
        )
    },
});
