<template>
    <div>
        <div class="channel-detail-results-wrapper row">
            <div v-for="item in result_data.hits" class="col-lg-16 col-md-8">
                <a :href="gameUrl(item)" class="channel-detail-result" :class="{'channel-detail-result__selected' : isSelected(item)}">                    <div class="row align-items-center">
                        <div class="col-lg-8 col-16">
                            <div class="match-results">
                                <div class="match-results__team match-results__team--home">
                                    <span class="hidden-xs hidden-sm match-results__team-name">{{ item.home_team.name }}</span>
                                    <img class="img-fallback" :src="item.home_team.club.logo != null ? item.home_team.club.logo : item.home_team.club.logo_url" :alt="item.home_team.club.name">
                                </div>
                                <div class="team match-results__state"><span>{{ item.home_goals }}</span> : <span>{{ item.away_goals }}</span>
                                </div>
                                <div class="team match-results__team team match-results__team--away">
                                    <img class="img-fallback" :src="item.away_team.club.logo != null ? item.away_team.club.logo : item.away_team.club.logo_url" :alt="item.away_team.club.name">
                                    <span class="hidden-xs hidden-sm team match-results__team-name">{{ item.away_team.name }}</span>
                                  <span v-if="item.is_livestream" style="margin-left: 20px"><img src="/static/images/livetab.svg" height="20px" style="margin-top: 4px"></span>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-8 col-16">
                            <div class="channel-detail-result__right">
                                <div class="row align-items-center">
                                    <div class="visible-xs visible-sm match-results__team-name col-16">{{
                                        item.home_team.name }} VS. {{ item.away_team.name }}
                                    </div>
                                    <div class="col-lg-4 channel-detail-result__info channel-detail-result__date">
                                        <i class="far fa-calendar hidden-xs hidden-sm "></i>
                                        <span>{{ (item.scheduled_start ? item.scheduled_start : item.created)|format_date("DD.MM.YYYY") }}</span>
                                        <span v-show="item.total_views > 15" class="visible-xs visible-sm views channel-detail-views">
                                            {{ get_number_formatted(item.total_views) }}
                                            <i class="fas fa-eye"></i>
                                        </span>
                                    </div>
                                    <div class="col-lg-8 col-16 channel-detail-result__info channel-detail-result__league channel-detail-league">
                                        <i class="sport-icons8-trophy"></i><span>{{ item.league.name }}</span></div>
                                    <div class="col-lg-4 col-16 channel-detail-result__info channel-detail-result__views">
                                        <span v-show="item.total_views > 15" class="hidden-xs hidden-sm channel-detail-views">{{ get_number_formatted(item.total_views) }}<i
                                                class="fas fa-eye"></i></span>
                                        <div class="channel-detail-result__icon-wrapper"><span
                                                class="visible-xs visible-sm">Show match details</span><i
                                                class="sport-chevron-circle-right channel-detail-result__icon"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </a>
            </div>
        </div>
        <div v-if="result_data.loading" class="text-center"><h3>Loading...</h3></div>
        <div v-else class="channel-detail-results-load">
            <button class="button" v-if="showLoadLess" @click="loadLess">
                <div class="add-button__icon"><i class="fas fa-minus"></i></div>
                <span>Reset load more</span></button>
            <button class="button button--secondary" v-if="showLoadMore" @click="loadMore">
                <div class="add-button__icon"><i class="fas fa-plus"></i></div>
                <span>Load more</span></button>
        </div>
    </div>
</template>

<script>
    import AbstractResult from './AbstractResult';
    import get from 'lodash/get';
    import {pathJoin} from "../../utils";

    export default {
        name: "DetailGamesResult",
        extends: AbstractResult,
        props: {
            embed: {
                type: Boolean,
                required: false,
                default: false
            },
            // defines which part of url should be kept
            keepSameUrl: {
                type: Number,
                required: false,
                default: 0
            },
            gameSlug: {
                type: String,
                default: null
            },
        },
        data() {
            return {
                result_name: 'game_detail',
                load_step: 10,
            }
        },
        computed: {
            showLoadLess(){
                const showLess = get(window, 'channelProperties.showLess', false);
                return this.$store.state.filter_store.search_data[this.result_name].from !== 0 && showLess;
            }
        },
        methods: {
            isSelected(item){
              return this.gameSlug === item.slug
            },
            loadLess() {
                const initShown = get(window, `channelProperties.detail_games_shown.${this.$mq}`, 10);
                this.$store.dispatch('filter_store/resetLoadMore', {[this.result_name]: {size: initShown, from: 0}})
            },
            gameUrl(game) {
                let splitted_path = window.location.pathname.split("/").filter(Boolean);
                let get_params = window.location.search;
                if (this.keepSameUrl > 0) {
                    let new_path = splitted_path.slice(0, this.keepSameUrl);
                    new_path.push(game.slug);
                    return "/" + pathJoin(new_path) + get_params;
                }
                else if (this.embed)
                    return game.iframe_url + get_params;
                else
                    return game.url + get_params;
            },
        }
    }
</script>

<style lang="scss" scoped>
    img {
        width: 20px;
    }
</style>
